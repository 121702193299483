import {HttpClient} from "@angular/common/http"
import {Injectable} from "@angular/core"
import {Router} from "@angular/router"
import {JwtHelperService} from "@auth0/angular-jwt"
import {BehaviorSubject, Observable, Subject, tap} from "rxjs"
import {ToastService} from "../../../../../src/app/services/toast.service"
import {environment} from "../../../../../src/environments/environment"
import {TokenDTO, UserResponseDTO} from "../../../b2b-models/src/lib/Entities"
import {User, Utente} from "../../../b2b-models/src/lib/EntitiesOld"

export const USER_KEY = "auth-user"

@Injectable({
  providedIn: "root"
})
export class B2bLoginService {
  private baseUrl = environment.accessesUrl + "/api/v2/users"
  public isLoggedSubject$ = new Subject()
  // public isLogged$ = this.isLoggedSubject$.asObservable();
  private userDataSubject$ = new BehaviorSubject(null)
  public userData$ = this.userDataSubject$.asObservable()

  constructor(private httpClient: HttpClient, private router: Router, private toast: ToastService) {}

  /**
   * Method for the backend call used for logging.
   * @param entity
   */
  login(entity: User): Observable<TokenDTO> {
    return this.httpClient.post<TokenDTO>(`${this.baseUrl}/login`, entity).pipe(
      tap((response: any) => {
        this.handleLogin(response.payload.accessToken, response.payload.refreshToken)
      })
    )
  }

  private handleLogin(accessToken: string, refreshToken: string) {
    const user: TokenDTO = {
      accessToken: accessToken,
      refreshToken: refreshToken
    }
    this.saveUser(user)
  }

  public saveUser(user: any): void {
    this.resetAuthUser()
    sessionStorage.setItem(USER_KEY, JSON.stringify(user))
    localStorage.setItem(USER_KEY, JSON.stringify(user))
  }

  public getUserId(): string {
    const helper = new JwtHelperService()
    const user = JSON.parse(localStorage.getItem("auth-user")!)
    const token = user?.accessToken
    if (token) {
      return helper.decodeToken(token).userID
    }
    return ""
  }

  public getDecodedUser(): any {
    const helper = new JwtHelperService()
    const user = this.getLoggedUser()
    const token = user?.accessToken
    if (token) {
      return helper.decodeToken(token)
    }
    return ""
    //per restituire i ruoli
    //console.log("ruolo utente", user.roles)
  }

  public isLoginGuest(): boolean {
    const user = this.getDecodedUser()
    if (user?.roles) {
      return user.roles.includes("ROLE_GUEST") ? true : false
    }
    return false
  }

  /**
   * Method that convert the session storage item userDTO for the object userDTO
   */
  public getLoggedUser(): any {
    const user = localStorage.getItem(USER_KEY)
    if (user) {
      return JSON.parse(user)
    }

    return null
  }

  /**
   * Method that return a boolean for let know if the user is or not logged.
   */
  public isLoggedIn(): boolean {
    const user = localStorage.getItem(USER_KEY)
    if (user) {
      return true
    }
    return false
  }

  /**
   * Method used to retrieve the accessToken of the logged user.
   */
  getAccessToken() {
    if (this.getLoggedUser()) {
      const user = JSON.parse(localStorage.getItem("auth-user")!)
      return user.accessToken
    } else {
      return ""
    }
  }

  /**
   * Method used to retrieve the refreshToken of the logged user.
   */
  getRefreshToken() {
    if (this.getLoggedUser()) {
      const user = JSON.parse(localStorage.getItem("auth-user")!)
      return user.refreshToken
    } else {
      return ""
    }
  }

  /**
   * Method for setting the RefreshToken of the logged user.
   */
  setRefreshToken(newRefreshToken: string) {
    if (this.getLoggedUser()) {
      const user = JSON.parse(localStorage.getItem("auth-user")!)
      user.refreshToken = newRefreshToken
      this.saveUser(user)
    }
  }

  /**
   * Method for setting the AuthToken of the logged user.
   */
  setAuthToken(newAccessToken: string) {
    if (this.getLoggedUser()) {
      const user = JSON.parse(localStorage.getItem("auth-user")!)
      user.accessToken = newAccessToken
      this.saveUser(user)
    }
  }

  /**
   * Method that clear the session and local storage of the auth-user
   */
  resetAuthUser() {
    localStorage.removeItem(USER_KEY)
    sessionStorage.removeItem(USER_KEY)
  }

  /**
   * Method for logout.
   */
  logout(): void {
    this.resetAuthUser()
    this.router.navigate(["/home"]).then(() => {
      this.toast.showSuccessToast("Success", "Logout effettuato con successo")
      window.location.reload()
    })
  }

  addUtenteFromSocial(user: Utente) {
    return this.httpClient.post<any>(`${this.baseUrl}/social`, user)
  }

  refreshTokens(accessToken: string, refreshToken: string): Observable<any> {
    const tokens = {accessToken, refreshToken}
    return this.httpClient.post<any>(`${this.baseUrl}/token-refresh`, tokens)
  }

  validateUser(payload: any) {
    return this.httpClient.post<any>(`${this.baseUrl}/validate`, payload)
  }

  getUserById(userId: string): Observable<UserResponseDTO> {
    return this.httpClient.get<UserResponseDTO>(`${this.baseUrl}/${userId}`)
  }

  setUserData(userData: any) {
    this.userDataSubject$.next(userData)
  }
}
